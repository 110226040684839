.App {
  text-align: left;
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.main {
  min-height: calc(100vh - 50px);
}


/*

*/
.dark-1 {
  background-color: #394B59;
  color: #f5f8fa;
}
.dark-2 {
  background-color: #30404D;
  color: #f5f8fa;
}
.dark-3 {
  background-color: #293742;
  color: #f5f8fa;
}
.dark-4 {
  background-color: #202B33;
  color: #f5f8fa;
}
.dark-5 {
  background-color: #182026;
  color: #f5f8fa;
}

.light-1 {
  background-color: #CED9E0;
  color: #182026;
}
.light-2 {
  background-color: #D8E1E8;
  color: #18202e;
}
.light-3 {
  background-color: #E1E8ED;
  color: #18202e;
}
.light-4 {
  background-color: #EBF1F5;
  color: #18202e;
}
.light-5 {
  background-color: #F5F8FA;
  color: #18202e;
}

.repo-list-portal>div{
  transform: translate3d(0px, 50px, 0px) !important;
  left: unset !important;
  right: 16px;
}

.repo-list .bp3-popover-content {
  padding: 5px;
  width: 375px;
}
@media only screen and (max-width: 400px) {
  .repo-list .bp3-popover-content {
    width: calc( 100vw - 25px );
  }
}
.repo-list-target{
  width: 100%;
}
.repo-list .bp3-menu {
  max-height: 300px;
  max-width: 400px;
  overflow: auto;
  padding: 0;
}
.repo-list strong{
  color: darkgreen;
}
.repo-list,.bp3-dark strong{
  color: lightgreen;
}
.DirTreeView{
  height: inherit;
  width: inherit;
  box-shadow: none !important;
}