@import "~@blueprintjs/core/lib/scss/variables";

.unsaved{
    color: $vermilion4;
}

.uncommitted {
    color: $gold4;
}

.local {
    color: $dark-gray5;
}

.notfound {
    color: $gray3;
}


.bp3-dark{
    .unsaved{
        color: $vermilion5;
    }

    .uncommitted {
        color: $gold5;
    }

    .local {
        color: $gray5;
    }

    .notfound {
        color: $gray2;
    }
}