.side-panel-toggle-button>.bp3-button-text{
    overflow: hidden;
    text-overflow: ellipsis;
}
.branch-list{
    padding: 5px;
}
.bp3-collapse{ 
    overflow-y: hidden !important;
}
.bp3-collapse-body{
    height: 100%;
}
.collapsed-scroll>.simplebar-wrapper{
    height: 100vh;
}