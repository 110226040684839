.codeeditor-statu-bar{
    text-align: center;
    margin: 0;
    padding: 2px;
    font-size: 14px;
}
.codeeditor-statu-bar.light{
    background-color: #f0f0f0;
}
.codeeditor-statu-bar.dark{
    background-color: #232323;
}