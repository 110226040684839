.tab-nav{
    white-space: nowrap;
    overflow: visible;
}
.tab-nav.dark{
    background-color: #202B33;
}
.tab-nav.light{
    background-color: #EBF1F5;
}
.tab-button {
    padding: 8px 8px 8px 8px;
    text-overflow: ellipsis ;
    white-space: nowrap;
    cursor: pointer;
    border: 0;
    margin-right: 1px;
    display: inline-block;
    overflow: hidden;
}
.tab-button.dark{
    /*background-color: #293742;*/
    background-color: #394B59;
}
.tab-button.light{
    /*background-color: #E1E8ED;*/
    background-color: #CED9E0;
}
.tab-button.dark.active{
    /*background-color: #394B59;*/
    background-color: #232323;
}
.tab-button.light.active{
    /*background-color: #CED9E0;*/
    background-color: #f0f0f0;
}
.tab-button:focus{
    border: 0;
    outline: none;
}
.tab-button:last-child{
    margin-right: 0;
}