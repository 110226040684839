.resizable-width{
    /*transition: all 0.3s ease;*/
    height: inherit;
    cursor: auto;
    position: relative;
    overflow: visible;
    display: grid;
}
.resizable-height{
    /*transition: all 0.3s ease;*/
    width: inherit;
    cursor: auto;
    position: relative;
    overflow: visible;
    display: grid;
}
.resizing-sensor{
    z-index: 10;
}
.resizing-sensor.left,.resizing-sensor.right{
    cursor: ew-resize;
    height: 100%; 
    width: 6px;
    top: 0;
}
.resizing-sensor.top,.resizing-sensor.bottom{
    cursor: n-resize;
    width: 100%; 
    height: 6px;
    right: 0;
}
.resizing-sensor.right{
    right: -3px;
}
.resizing-sensor.left{
    left: -3px;
}
.resizing-sensor.top{
    top: -3px;
}
.resizing-sensor.bottom{
    bottom: -3px;
}
.resizing-sensor.right:hover,.resizing-sensor.left:hover{
    /*background-color: rgba(100, 100, 100, 0.3);*/
    width: 20px;
}
.resizing-sensor.top:hover,.resizing-sensor.bottom:hover{
    /*background-color: rgba(100, 100, 100, 0.3);*/
    height: 20px;
}
.resizing-sensor.right:hover{
    right: -10px;
}
.resizing-sensor.left:hover{
    left: -10px;
}
.resizing-sensor.top:hover{
    top: -10px;
}
.resizing-sensor.bottom:hover{
    bottom: -10px;
}
.resizing-sensor.right.active{
    width: 400px;
    right: -200px;
}
.resizing-sensor.left.active{
    width: 400px;
    left: -200px;
}
.resizing-sensor.top.active{
    height: 400px;
    top: -200px;
}
.resizing-sensor.bottom.active{
    height: 400px;
    bottom: -200px;
}

.resizable-toggle{
    cursor: pointer;
    position: absolute;
    z-index: 20;
    background-color: inherit;
    /*border-radius: 20px;*/
}
.resizable-toggle.right,.resizable-toggle.left{
    top: 24px;
}
.resizable-toggle.top,.resizable-toggle.bottom{
    right: 24px;
}
.resizable-toggle{
    transition: all 1s ease;
}
.resizable-toggle.right{
    /*right: -12px;*/
    right: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0;
}
.resizable-toggle.left{
    /*left: -12px;*/
    left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px;
}
.resizable-toggle.top{
    /*top: -12px;*/
    top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.resizable-toggle.bottom{
    /*bottom: -12px;*/
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.resizable-toggle.right.collapsed{
    right: -24px;
}
.resizable-toggle.left.collapsed{
    left: -24px;
}
.resizable-toggle.top.collapsed{
    top: -24px;
}
.resizable-toggle.bottom.collapsed{
    bottom: -24px;
}
.resizable-toggle.right.collapsed,.resizable-toggle.left.collapsed{
    transform: rotateY(180deg);
}
.resizable-toggle.top.collapsed,.resizable-toggle.bottom.collapsed{
    transform: rotateX(180deg);
}